import React from "react"
import FooterImage from "../images/footer_background.svg"
import TickethossOrange from "../images/tickethoss_orange.svg"
import PitPayOrange from "../images/pitpayicon.svg"
import AndroidImage from "../images/GoogleBadge.svg"
import AppleImage from "../images/AppleBadge.svg"
import Facebook from "../images/fb.svg"
import Instagram from "../images/ig.svg"
import Twitter from "../images/tw.svg"
import Linkedin from "../images/linkedin.svg"
import {
  FooterMainContainer,
  FooterContentContainer,
  FirstRowWrapper,
  SecondRowWrapper,
  TickethossOrangeContainer,
  FooterText,
  AppContainer,
  AppLink,
  AppImage,
  TextContainer,
  LinksContainer,
  FooterLink,
  SocialsContainer,
  SocialLink,
  TextMobileContainer,
} from "../styles/footer.style"

export default function Footer() {
  const isTickethoss = process.env.GATSBY_APP_PLATFORM === "tickethoss"

  return (
    <FooterMainContainer style={{ backgroundImage: `url(${FooterImage})` }}>
      <FooterContentContainer>
        <FirstRowWrapper className="Row">
          <TickethossOrangeContainer
            src={isTickethoss ? TickethossOrange : PitPayOrange}
            alt="Tickethoss Orange"
          />
          <FooterText>
            Download {isTickethoss ? "Ticket Hoss" : "Pit Pay"}!
          </FooterText>
          <AppContainer>
            <AppLink
              href={
                isTickethoss
                  ? "https://apps.apple.com/us/app/pit-pay/id1605673660?ls=1"
                  : "https://apps.apple.com/us/app/pit-pay/id1489064521?ls=1"
              }
              target="_blank"
            >
              <AppImage src={AppleImage} alt="App Store" />
            </AppLink>
            <AppLink
              href={
                isTickethoss
                  ? "https://play.google.com/store/apps/details?id=com.tickethoss.tickethoss"
                  : "https://play.google.com/store/apps/details?id=com.pitpay.pitpay"
              }
              target="_blank"
            >
              <AppImage src={AndroidImage} alt="Goggle Play" />
            </AppLink>
          </AppContainer>
        </FirstRowWrapper>
        <SecondRowWrapper className="Row">
          <TextContainer>
            <p
              style={{
                paddingRight: 15,
                whiteSpace: "nowrap",
                fontSize: "1.125em",
                lineHeight: "24px",
              }}
            >
              © Pit Pay Inc. All Rights Reserved.
            </p>
          </TextContainer>
          <LinksContainer>
            <FooterLink to="/terms">Terms of Service</FooterLink>
            <FooterLink to="/privacy">Privacy Policy</FooterLink>
            <FooterLink to="/refunds">Refund Policy</FooterLink>
            <FooterLink to={isTickethoss ? "/ticket-license" : "/privacy"}>
              Ticket License
            </FooterLink>
            <FooterLink
              to={
                isTickethoss
                  ? "https://dashboard.tickethoss.com"
                  : "https://dashboard.pitpay.com"
              }
              target="_blank"
            >
              Dashboard
            </FooterLink>
          </LinksContainer>
          <TextMobileContainer>
            © Pit Pay Inc. All Rights Reserved.
          </TextMobileContainer>
          <SocialsContainer>
            <SocialLink
              href={
                isTickethoss
                  ? "https://www.facebook.com/TicketHoss/"
                  : "https://www.facebook.com/PitPayApp"
              }
              target="_blank"
            >
              <img src={Facebook} alt="App Store" />
            </SocialLink>
            <SocialLink
              href={
                isTickethoss
                  ? "https://www.instagram.com/tickethoss/"
                  : "https://www.instagram.com/pitpayapp/"
              }
              target="_blank"
            >
              <img src={Instagram} alt="Instagram" />
            </SocialLink>
            <SocialLink
              href={
                isTickethoss
                  ? "https://twitter.com/tickethoss"
                  : "https://twitter.com/pitpayapp"
              }
              target="_blank"
            >
              <img src={Twitter} alt="Twitter" />
            </SocialLink>
            <SocialLink
              href={"https://www.linkedin.com/company/pitpay/about/"}
              target="_blank"
            >
              <img src={Linkedin} alt="Linkedin" />
            </SocialLink>
          </SocialsContainer>
        </SecondRowWrapper>
      </FooterContentContainer>
    </FooterMainContainer>
  )
}
